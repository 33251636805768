@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

.grid {
  display: -webkit-inline-box;
}

.card {
  width: 295px;
  overflow: hidden;
  border-radius: 25px;
  border: 1px solid lavender;
  margin: 10px;
  box-shadow: 5px 5px 15px 5px rgba(230, 230, 250, 1);
}

.card__image {
  position: relative;
  height: 140px;
}
.card__image > img {
  max-width: 100%;
  border-bottom-right-radius: 30px;
  transform: rotate(10deg) translate(-10px, -55px);
  position: absolute;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.card__info {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0px 30px 20px 30px;
}
.card__info h3 {
  font-size: 18px;
  font-weight: 700;
}
.card__info p {
  font-size: 14px;
  font-weight: 600;
}
.card__info--price {
  text-align: right;
  color: orangered;
}

button {
  background-color: red;
  color: white;
  border: 1px solid white;
  margin-left: 8px;
  cursor: pointer;
  border-radius: 15px;
  font-weight: 900;
  font-size: 15px;
}
